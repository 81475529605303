import MockAdapter from 'axios-mock-adapter'
import axios from 'axios'

const mock = new MockAdapter(axios)

if (process.env.REACT_APP_DEBUG !== 'true') { 
    mock.restore()
}

export default mock
