const initialState = {
  tickets: [],
  messages: [],
  selectedTicket: {}
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CHAT_CONTACTS':
      return { ...state, tickets: action.data.tickets }
    case 'SELECT_CHAT':
      return { ...state, selectedTicket: action.data.ticket, messages: action.data.messages }
    case 'UNSELECT_CHAT':
      return { ...state, selectedTicket: {}, messages: [] }
    default:
      return state
  }
}

export default chatReducer
