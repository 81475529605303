// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'

import chat from '@views/chat/store/reducer'
import contacts from '@views/contacts/store/reducer'
import protocol from '@views/reports/pages/protocol/store/reducer'

import chatExample from '@views/examples/apps/chat/store/reducer'
import todoExample from '@views/examples/apps/todo/store/reducer'
import usersExample from '@views/examples/apps/user/store/reducer'
import emailExample from '@views/examples/apps/email/store/reducer'
import invoiceExample from '@views/examples/apps/invoice/store/reducer'
import calendarExample from '@views/examples/apps/calendar/store/reducer'
import ecommerceExample from '@views/examples/apps/ecommerce/store/reducer'
import dataTablesExample from '@views/examples/tables/data-tables/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  chat,
  contacts,
  protocol,
  /* it's important to maintain the old example to help us to build morre screens in the future */
  chatExample,
  todoExample,
  usersExample,
  emailExample,
  invoiceExample,
  calendarExample,
  ecommerceExample,
  dataTablesExample
})

export default rootReducer
